export default {
  async update (Vue, id, formData, idsistema) {
    const tables = Vue.$offline.db.tables
    await Vue.$offline.clienteTelefono.updateSync({
      idcliente_telefono: id,
      idttelefono: formData.idttelefono,
      telefono: formData.telefono,
      horario: formData.horario,
      observaciones: formData.observaciones,
      email: formData.email,
      email_notifica_admon: formData.email_notifica_admon,
      nombre: formData.nombre,
      email_notifica_comercial: formData.email_notifica_comercial,
      email_notifica_sat: formData.email_notifica_sat,
      para_todos_los_sistemas: formData.para_todos_los_sistemas,
    })
    if (idsistema && Vue.$offline.db.isOfflinePk(id)) {
      // solo permitir modificar datos de CRA de registros nuevos
      // no se descargan datos de CRA (ver download.js)
      const sistemaTelefono = (await Vue.$offline.sistemaTelefono.select({
        where: Vue.$offline.db.op.and(
          tables.sistema_telefono.idsistema.eq(idsistema),
          tables.sistema_telefono.idcliente_telefono.eq(id),
          tables.sistema_telefono.estado.gt(0),
        )
      }))[0]
      await Vue.$offline.sistemaTelefono.updateSync({
        idsistema_telefono: sistemaTelefono.sistema_telefono.idsistema_telefono,
        orden_cra: formData.orden_cra,
        codigo_cra: formData.codigo_cra,
        codigo_coaccion_cra: formData.codigo_coaccion_cra,
      })
    }
  },
}
