export default {
  selectTtelefono (Vue) {
    const tables = Vue.$offline.db.tables
    const ttelefono = Vue.$offline.ttelefono
    return Vue.$offline.db
      .select()
      .from(tables.ttelefono)
      .innerJoin(ttelefono.dummyTable, ttelefono.pk.eq(ttelefono.dummyPk))
      .where(tables.ttelefono.estado.gt(0))
      .orderBy(tables.ttelefono.descripcion)
      .exec()
  },
  async selectFormData (Vue, id, idsistema) {
    const tables = Vue.$offline.db.tables
    if (idsistema) {
      return (await Vue.$offline.db
        .select()
        .from(tables.sistema_telefono)
        .innerJoin(
          tables.cliente_telefono,
          tables.sistema_telefono.idcliente_telefono.eq(tables.cliente_telefono.idcliente_telefono)
        )
        .where(
          Vue.$offline.db.op.and(
            tables.cliente_telefono.idcliente_telefono.eq(id),
            tables.sistema_telefono.idsistema.eq(idsistema),
          )
        )
        .exec()
      )[0]
    } else {
      return await Vue.$offline.clienteTelefono.row(id)
    }
  },
}
